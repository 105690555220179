$main_color: rgb(6, 23, 72);

/* custom scrollbar */
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #949494;
  border-radius: 20px;
  border: 2px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

// change color input
// input:-webkit-autofill,
// input:-webkit-autofill:focus {
// 	transition: background-color 600000s 0s, color 600000s 0s;
// }

// form control

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  // color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus-visible {
    outline: #495057 1px;
  }
  &:hover {
    border-color: black;
  }
  &:focus {
    // color: #495057;
    background-color: #fff;
    border-color: rgba(0, 0, 0, 0.23);
    outline: 2px solid $main_color;
    // box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
}

.form-control-select {
  padding: 0.375rem 0.75rem;
}

// change select icon dropdown
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;utf8,<svg fill='hsl(0deg 0% 80%)' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 5px);
  background-position-y: 8px;
  // border: 1px solid #dfdfdf;
  // border-radius: 2px;
  // margin-right: 2rem;
}

// custome drop down year react date picker
.react-datepicker {
  &__triangle {
    display: none;
  }
  &__year-wrapper {
    max-width: 210px !important;
  }
  &__year-text {
    padding: 8px;
  }
}

.vff-custom-date-icon-input {
  background-image: url("data:image/svg+xml;utf8,<svg fill='hsl(0deg 0% 80%)' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M19,19H5V8H19M16,1V3H8V1H6V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3H18V1M17,12H12V17H17V12Z' /></svg>");
  background-repeat: no-repeat;
  background-position-x: calc(100% - 2px);
  background-position-y: 6px;
  background-size: 28px;
  padding-left: 6px !important;
}

.react-datepicker__calendar-icon {
  display: none !important;
}